export default defineAppConfig({
    ui: {
        notification: {
            // Show toasts at the top right of the screen
            // background: 'bg-green_dark',
            // title: 'text-white',
            // description: 'text-white',
            ring: 'ring-0',
            default: {
                timeout: 2000,
                icon: 'i-heroicons-check-circle'
            },
            avatar: {
                base: 'avatar-notification',
            },
            icon: {
                color: 'text-green_dark dark:text-green_dark',
            },
        },
        notifications: {
            position: 'md:top-0 md:bottom-auto',
        },
        button: {
            base: 'disabled:cursor-pointer',
            variant: {
                solid: 'rounded-sm text-white bg-green_dark hover:bg-grey_dark disabled:bg-grey_disabled disabled:text-grey_light uppercase font-size-11 place-content-center',
                outline: 'rounded-sm hover:bg-grey_dark hover:text-white disabled:border-grey disabled:text-grey uppercase font-size-11 place-content-center',
                // soft: 'text-{color}-500 dark:text-{color}-400 bg-{color}-50 hover:bg-{color}-100 disabled:bg-{color}-50 dark:bg-{color}-950 dark:hover:bg-{color}-900 dark:disabled:bg-{color}-950 focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-{color}-500 dark:focus-visible:ring-{color}-400',
                // ghost: 'text-{color}-500 dark:text-{color}-400 hover:bg-{color}-50 disabled:bg-transparent dark:hover:bg-{color}-950 dark:disabled:bg-transparent focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-{color}-500 dark:focus-visible:ring-{color}-400',
                // link: 'text-{color}-500 hover:text-{color}-600 disabled:text-{color}-500 dark:text-{color}-400 dark:hover:text-{color}-500 dark:disabled:text-{color}-400 underline-offset-4 hover:underline focus-visible:ring-2 focus-visible:ring-inset focus-visible:ring-{color}-500 dark:focus-visible:ring-{color}-400',
            },
        },
        input: {
            color: {
                white: {
                    outline: 'focus:ring-green_dark focus:ring-1'
                }
            }
        },
        // avatar: {
        //     rounded: 'rounded-0',
        // }
    }
})
